import { type FC, memo } from 'react'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import { getTranslations } from '@/context'
import { useScrollTracker } from '@dy/commons/hooks'
import { HeadTag } from '@dy/commons/components'
import { LayoutEmpty, SectionStorePicker, HeaderHome, SectionBrands } from '@/components'
import type { ISectionHighlights, ISectionBestseller } from '@/components'
import { initializeApollo, GET_HOMEPAGE, GET_LAYOUT } from '@/api'
import { CATEGORIES, BANNER, DY_SIGNUP_ALLOWED } from '@dy/commons/utils'
import { mq, vw } from '@dy/commons/styles'
import type { TypeMarqueeProps } from '@dy/commons/components'

const DynamicMarquee = dynamic<TypeMarqueeProps>(() => import('@dy/commons/components').then((mod) => mod.Marquee), { ssr: false })
const DynamicSectionHighlights = dynamic<ISectionHighlights>(() => import('../components/home/SectionHighlights').then((mod) => mod.SectionHighlights))
const DynamicSectionBestseller = dynamic<ISectionBestseller>(() => import('../components/home/SectionBestseller').then((mod) => mod.SectionBestseller))
const DynamicSubscribe = dynamic(() => import('../components/home/Subscribe').then((mod) => mod.Subscribe))

type TypeHomePage = {
  data: any,
  layout: any,
  translations?: any
}

export async function getServerSideProps({ locale }) {
  const dictionary = getTranslations(locale, ['home', 'account', 'shop', 'product_page', 'section_subscribe'])
  const apolloClient = initializeApollo()

  const { data:resHomepage } = await apolloClient.query({
    query: GET_HOMEPAGE,
    context: {
      isPrivatePath: false
    }
  })

  const { data:resLayout } = await apolloClient.query({
    query: GET_LAYOUT,
    variables: {
      bannerType: BANNER.SLIM,
      type: CATEGORIES.PRODUCT
    },
    context: {
      isPrivatePath: false
    }
  })

  let modals
  if(resLayout?.modals) modals = resLayout?.modals?.edges?.map(modal => modal.node)

  if (!resHomepage || !resLayout) return { notFound: true }
  return {
    props: {
      data: {...resHomepage.homepage },
      translations: dictionary,
      layout:{...resLayout, modals},
      initialApolloState: apolloClient.cache.extract()
    }
  }
}

const HomePage: NextPage<TypeHomePage> & { Layout?:FC } = memo(({ data }) => {
  const { seo, banners:[header, ...productHighlighted], topSellers, highlighted, brands, services } = data
  const { locale } = useRouter()
  const scrollPos = useScrollTracker(1)

  return (
    <>
      {locale === 'en-EU' ?
        <SectionStorePicker />
        :
        <>
          <HeadTag seo={seo} jsonld={{data: data, pageType: 'home'} } pageType='home' />
          {header && <HeaderHome data={header} />}
          <Main $scrollTop={scrollPos === 'TOP'}>
            {(DY_SIGNUP_ALLOWED && (locale === 'es-CT' || locale === 'es-MD')) && <DynamicSubscribe />}
            {highlighted.length > 0 && <DynamicSectionBestseller data={{ topSellers, highlighted }} />}
            {productHighlighted.length > 0 && <DynamicSectionHighlights data={productHighlighted} />}
            {brands.length > 0 && <SectionBrands brands={brands} />}
            {services && Object.keys(services).length > 0 && <DynamicMarquee data={services.title} />}
          </Main>
        </>
      }
    </>
  )
})

HomePage.Layout = LayoutEmpty
export default HomePage


const Main = styled.main<{ $scrollTop?:boolean }>`
  overflow-x: clip;

  + div #detail-page-wrapper {
    ${mq.greaterThan('tablet')} {
      top: ${({ $scrollTop }) => vw($scrollTop ? 85 : 45, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      top: ${({ $scrollTop }) => $scrollTop ? 85 : 45}px;
    }
  }
`
